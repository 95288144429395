import { useSetRecoilState } from "recoil";
import { guestPopupState } from "../recoil/popup/guestPopupState";

export const useOpenGuestPopup = () => {
  const setGuestPopup = useSetRecoilState(guestPopupState);

  const getHeaderTitle = (type) => {
    if (type === "create") return "Create & Send invite";
    if (type === "edit") return "Edit event";
    if (type === "delete") return "Delete event";
    return "";
  };

  const getMessageType = (type) => {
    if (type === "create") return "invitation";
    if (type === "edit") return "update";
    if (type === "delete") return "cancellation";
    return "";
  };

  const getButtonTitle = (type) => {
    if (type === "create") return "Send invite";
    return "Send update";
  };
  const openGuestPopup = (updateTask, type) => {
    return new Promise((resolve, reject) => {
      setGuestPopup({
        type: type,
        isVisible: true,
        headerTitle: getHeaderTitle(type),
        message: `Would you like to send ${getMessageType(type)} emails?`,
        okButtonTitle: getButtonTitle(type),
        closeButtonTitle: "Don't send",
        discardButtonTitle: "Discard Changes",
        data: updateTask,
        resolve,
        reject,
      });
    });
  };
  return { openGuestPopup };
};
