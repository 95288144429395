import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { createPortal } from "react-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { COMPLETED, INPROGRESS } from "../../constants/taskStateType";
import useTaskUpdater from "../../hooks/useTaskUpdate";
import { accountState, projectColorListState } from "../../recoil/account/accountState";
import { draggedEventState } from "../../recoil/calendar/calendarState";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { toastState } from "../../recoil/toast/toastState";
import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import InboxReorderDragLayer from "./InboxReorderDragLayer";
import SidebarModal from "./SidebarModal";
import styles from "./TaskRow.module.css";

const TaskRow = React.memo(
  ({
    data,
    index,
    onDataDelete,
    onDataDuplicate,
    expand,
    loadData,
    onTasksReorderChange,
    taskSelectedId,
    onSaveTaskSelectedId,
    taskList,
    handleUpdateTaskList,
    listType,
  }) => {
    const [accountData, setAccountData] = useRecoilState(accountState);
    const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
    const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);
    const [rowIcon, setRowIcon] = useState(true);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
    const inputRef = useRef(null);
    const [iconPos, setIconPos] = useState(-100);
    const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
    const [integrationImg, setIntegrationImg] = useState(null);
    const [hover, setHover] = useState(false);

    const [prevIndex, setPrevIndex] = useState(index);
    const [isTaskRowSelected, setIsTaskRowSelected] = useState(taskSelectedId === data.id);
    const { updateTaskList, deleteTaskList } = useTaskUpdater(listType);
    const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
    const [doneTaskList, updateDoneTaskList] = useRecoilState(doneTaskListState);
    const setToast = useSetRecoilState(toastState);
    const api = useApi();
    const ref = useRef(null);
    const trackCall = useJuneTrackCall();

    useEffect(() => {
      const integrationData = data.integration?.provider || data.provider;
      if (integrationData && integrationData !== "") {
        setIntegrationImg(integrationData);
      }
    }, []);

    useEffect(() => {
      setIsTaskRowSelected(taskSelectedId === data.id);
    }, [taskSelectedId, data.id]);

    useEffect(() => {
      if (taskDetail.isVisible === false) {
        onSaveTaskSelectedId(null);
      }
    }, [taskDetail]);

    useEffect(() => {
      if (prevIndex !== index) {
        const animation = ref.current.animate(
          [
            { transform: `translateY(${(prevIndex - index) * 100}%)` },
            { transform: "translateY(0%)" },
          ],
          {
            duration: 150,
            easing: "ease-in-out",
          }
        );

        animation.onfinish = () => {
          setPrevIndex(index);
        };
      }
    }, [index, prevIndex]);

    const [{ isDragging }, drag, preview] = useDrag({
      type: "task",
      item: { dragType: "inbox", ...data },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: false });
    }, [preview]);

    const taskHover = useCallback(
      (item, monitor) => {
        if (!ref.current || isDragging) {
          return;
        }

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverIndex = index;
        const dragIndex = taskList.findIndex((task) => task.id === item.id);

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset ? clientOffset.y - hoverBoundingRect.top : null;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveTask(dragIndex, hoverIndex);
      },
      [index, taskList, isDragging]
    );

    const [, drop] = useDrop({
      accept: ["task"],
      hover: taskHover,
      drop(props, monitor) {
        onTasksReorderChange();
      },
    });

    const moveTask = useCallback(
      (dragIndex, hoverIndex) => {
        if (dragIndex === hoverIndex) {
          return;
        }

        const dragItem = taskList[dragIndex];
        const updatedDragItem = {
          ...dragItem,
          itemStatus: listType === "doneTask" ? COMPLETED : INPROGRESS,
          taskType: "Task",
        };

        handleUpdateTaskList((prevTasks) => {
          const updatedTasks = [...prevTasks];
          updatedTasks.splice(dragIndex, 1);
          updatedTasks.splice(hoverIndex, 0, updatedDragItem);
          return updatedTasks;
        });
      },
      [taskList, handleUpdateTaskList]
    );

    const iconStyle = {
      backgroundColor: hover
        ? `${data.projectId ? projectColorList[data.projectId] : "#abadad"}`
        : `${data.projectId ? projectColorList[data.projectId] : "#abadad"}${
            data.itemStatus === "Completed" ? (rowIcon ? "" : "33") : rowIcon ? "33" : ""
          } `,
      boxShadow: `0 0 0 1px ${data.projectId ? projectColorList[data.projectId] : "#abadad"} inset`,
    };

    const handleDelete = () => {
      setIsSidebarModalOn(false);
      onDataDelete(data);
    };

    const handleDuplicate = () => {
      setIsSidebarModalOn(false);
      onDataDuplicate(data);
    };

    const handleItemContextMenu = (e) => {
      e.preventDefault();
      const { clientX, clientY } = e;
      setModalPosition({ x: clientX, y: clientY });
      setIsSidebarModalOn(true);
      onSaveTaskSelectedId(data.id);
    };

    const handleIconEnter = (e) => {
      const rect = e.target.getBoundingClientRect();
      const centerY = rect.top + rect.height / 2 - 8;
      setIconPos(centerY);
      setHover(true);
    };

    const handleIconLeave = (e) => {
      setIconPos(-100);
      setHover(false);
    };

    const handleDoneOrUnDone = () => {
      setRowIcon(!rowIcon);
      let isDone = data.itemStatus === COMPLETED;
      api
        .patch("tasks/" + data.id + "/" + (isDone ? "undone" : "done"), "", {
          headers: { "X-Requester": data.creator },
        })
        .then(() => {
          handleUpdateTaskList((current) => {
            return deleteTaskList(current, data.id);
          });

          if (isDone) {
            updateInboxTaskList((current) => {
              return [{ ...data, itemStatus: INPROGRESS }, ...current];
            });

            trackCall("undone_block", {
              location: "inbox",
            });
          } else {
            updateDoneTaskList((current) => {
              return [{ ...data, itemStatus: COMPLETED }, ...current];
            });
            setToast({
              type: "Done",
              isVisible: true,
              message: "Task marked as done",
            });
          }

          // NOTE inbox에서는 task(개인)만 존재
          !isDone && trackCall("done_block", { location: "inbox" });
        });
    };

    const handleClick = (e) => {
      const { clientX, clientY } = e;

      let modalHeight = taskDetail.data ? taskDetail.data.modalHeight : 280;

      const rect = ref.current.getBoundingClientRect();
      let x = rect.right + 8;
      let y = rect.top;

      // 모달이 화면의 아래쪽을 벗어나면 아래쪽에 맞춤
      if (y + modalHeight > window.innerHeight) {
        y = window.innerHeight - modalHeight - 20;
      }
      setModalPosition({ x: x, y: y });

      if (taskDetail.isVisible) {
        // 모달 끄기
        setTaskDetail({
          isVisible: false,
          data: null,
          modalPosition: { x: 0, y: 0 },
          loadData: false,
          handleDataDuplicate: null,
          type: null,
        });
      } else {
        // 모달 켜기
        if (clientX !== 0 || clientY !== 0) {
          setTaskDetail({
            isVisible: true,
            data: data,
            modalPosition: { x: x, y: y },
            loadData: loadData,
            handleDataDuplicate: handleDuplicate,
            type: "inbox",
          });

          onSaveTaskSelectedId(data.id);
        }
      }
    };

    const handleDoubleClick = (e) => {
      // e.stopPropagation()
      const { clientX, clientY } = e;
      setModalPosition({ x: clientX, y: clientY });
    };

    const handleSideBarClose = () => {
      setIsSidebarModalOn(false);
      onSaveTaskSelectedId(null);
    };

    drag(drop(ref));

    return (
      <div className={draggedEvent && data.id === draggedEvent.id ? styles.dragOver : ""}>
        <div
          ref={ref}
          className={`${!expand ? styles.taskListAddOuterRectangle : styles["taskList-body"]} 
            ${isTaskRowSelected && styles.expandSelected}
            ${!draggedEvent && (expand ? styles.expandHoverd : styles.foldHoverd)}
            ${styles[`${isDragging ? "isDragging" : null}`]}`}
          onContextMenu={handleItemContextMenu}
          draggable="true"
          key={{ title: "task title" }}
          onDragStart={() =>
            setDraggedEvent({
              ...data,
              kind: "task",
              color: data.projectId ? projectColorList[data.projectId] : "#abadad",
            })
          }
          onDragEnd={() => {
            setDraggedEvent(null);
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={`${styles["taskList-body-icon"]} ${
              data.itemStatus === "Completed" ? styles["taskList-body-icon-done"] : ""
            }`}
            style={iconStyle}
            onMouseEnter={handleIconEnter}
            onMouseLeave={handleIconLeave}
            onClick={handleDoneOrUnDone}
          >
            {!expand && (
              <div className={styles["body-icon-hover"]} style={{ top: `${iconPos} px` }}>
                {/* {data.integrations} */}
              </div>
            )}
          </div>
          {expand && (
            <>
              <div
                className={styles["taskList-body-title"]}
                // value={tempData}
                // onChange={handleInputChange}
                // onKeyDown={handleInputKeyDown}
                // onBlur={handleInputBlur}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                // onDoubleClick={handleDoubleClick}
                ref={inputRef}
              >
                {data.title}
              </div>
              {integrationImg && (
                <div
                  className={`${styles["integration"]} ${
                    integrationImg === "gmail"
                      ? styles["integration-gmail"]
                      : integrationImg === "jira"
                        ? styles["integration-jira"]
                        : integrationImg === "slack" && styles["integration-slack"]
                  } `}
                ></div>
              )}
            </>
          )}
        </div>
        {isSidebarModalOn && (
          <SidebarModal
            x={modalPosition.x}
            y={modalPosition.y}
            onDelete={handleDelete}
            onDuplicate={handleDuplicate}
            onClose={handleSideBarClose}
          />
        )}
        {isDragging &&
          createPortal(
            <div style={{ cursor: "pointer" }}>
              <InboxReorderDragLayer />
            </div>,
            document.body
          )}
      </div>
    );
  }
);

export default TaskRow;
