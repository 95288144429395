import React from "react";
import styles from "../Main.module.css";

interface ViewToggleSwitchProps {
  value: boolean;
  onChange: (isSwitchOn: boolean) => void;
}

export const ViewToggleSwitch: React.FC<ViewToggleSwitchProps> = ({ value, onChange }) => {
  return (
    <div className={styles["body-switch"]}>
      <label className={styles["switch-label"]}>
        <input
          className={styles["switch-input"]}
          type="checkbox"
          checked={!value}
          onChange={() => onChange(!value)}
        ></input>
        <span className={styles["switch-slider"]}></span>
      </label>
      <div className={styles["switch-text"]}>
        <span>Calendar</span>
        <span>Wiki</span>
      </div>
    </div>
  );
};
