import { useMutation } from "@tanstack/react-query";
import useApi from "../services/auth/useApi";

interface DeleteRecurrenceBlockApiProps {
  eventId: string;
  option: "current" | "from" | "all";
  notify?: boolean;
  creator: string;
}

export const useDeleteRecurrenceBlock = () => {
  const api = useApi();

  const deleteRecurrenceBlock = async ({
    eventId,
    option,
    notify,
    creator,
  }: DeleteRecurrenceBlockApiProps): Promise<void> => {
    await api.delete(`tasks/recurrence`, {
      params: { eventId: eventId, option: option, ...(notify ? { notification: notify } : {}) },
      headers: { "X-Requester": creator },
    });
  };

  return useMutation<void, Error, DeleteRecurrenceBlockApiProps>({
    mutationFn: deleteRecurrenceBlock,
  });
};
