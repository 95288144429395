import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Main from "./views/Main";
import Login from "./views/Login";
import Join from "./views/Join";
import JoinWaitList from "./views/JoinWaitList";
import { createPortal } from "react-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Routes>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="main" element={<Main />} />
            <Route path="join" element={<Join />} />
            <Route path="joinWaitList" element={<JoinWaitList />} />
          </Routes>
        </div>
        {createPortal(<div id="event-tooltip" />, document.body)}
        {createPortal(<div id="popup-root" />, document.body)}
      </QueryClientProvider>
    </RecoilRoot>
  );
}
