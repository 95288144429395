import { useRecoilState } from "recoil";

import { useDrag } from "react-dnd";
import { draggedEventState } from "../../../recoil/calendar/calendarState";

function ShowMoreItem({ event, children, ...props }) {
  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);

  const [, drag] = useDrag({
    type: "moreTask",
    item: { dragType: "moreTask", ...draggedEvent },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      draggable="true"
      onDragStart={() => {
        return setDraggedEvent({ ...event, dragType: "moreTask" });
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export default ShowMoreItem;
