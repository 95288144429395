import { useSetRecoilState } from "recoil";
import { recurringPopupState } from "../recoil/popup/recurringPopupState";
import { capitalizeFirstLetter } from "../utils/common/stringFormat/capitalizeFirstLetter";

export const useOpenRecurringPopup = () => {
  const setRecurringPopup = useSetRecoilState(recurringPopupState);

  const openRecurringPopup = (updateTask, type, allEnabled = true) => {
    return new Promise((resolve, reject) => {
      setRecurringPopup({
        type: type, //  edit , delete
        isVisible: true,
        headerTitle: `${capitalizeFirstLetter(type)} recurring event`,
        message: "",
        okButtonTitle: "OK",
        closeButtonTitle: "Cancel",
        messageType: "current", // current, from, all
        isAllEnabled: allEnabled,
        data: updateTask,
        resolve,
        reject,
      });
    });
  };
  return { openRecurringPopup };
};
