import { useMutation } from "@tanstack/react-query";
import useApi from "../services/auth/useApi";

export const useUpdateTask = () => {
  const api = useApi();

  return useMutation({
    mutationFn: (data) =>
      api
        .patch(`tasks/${data.id}?creator=${data.creator}`, data, {
          headers: {
            "X-Requester": data.creator,
          },
        })
        .then((res) => res.data),
  });
};
