import ReactDOM from "react-dom";
import clsx from "clsx";

import { useRecoilState } from "recoil";
import { guestPopupState } from "../../../recoil/popup/guestPopupState";

import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";

import styles from "./GuestPopup.module.css";
import { useEffect, useRef } from "react";
import FocusTrap from "focus-trap-react";
import UserCancelledPopupError from "../../../errors/UserCancelledPopupError";

function GuestPopup({ guestPopupRef }) {
  const [popup, setPopup] = useRecoilState(guestPopupState);
  const sendButtonRef = useRef(null);

  const cancelButtonRef = useRef(null);
  const notSendButtonRef = useRef(null);

  useEffect(() => {
    if (popup.isVisible) {
      // 포커스 지연 설정을 위해 setTimeout 사용
      setTimeout(() => {
        if (sendButtonRef.current) {
          sendButtonRef.current.focus();
        }
      }, 0); // 0ms 지연으로 DOM 업데이트 직후 실행
    }
  }, [popup.isVisible]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowRight":
          event.preventDefault();
          const nextFocus =
            document.activeElement === sendButtonRef.current
              ? cancelButtonRef.current
              : document.activeElement === cancelButtonRef.current
                ? notSendButtonRef.current
                : sendButtonRef.current;
          nextFocus.focus();
          break;
        case "ArrowLeft":
          event.preventDefault();
          const prevFocus =
            document.activeElement === notSendButtonRef.current
              ? cancelButtonRef.current
              : document.activeElement === cancelButtonRef.current
                ? sendButtonRef.current
                : notSendButtonRef.current;
          prevFocus.focus();
          break;
        case "Enter":
          document.activeElement.click();
          break;
        case "Escape":
          handleCancel(event);
          break;
        default:
          break;
      }
    };

    if (popup.isVisible) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [popup.isVisible]);

  if (!popup.isVisible) return null;

  const closePopup = (clickType, resolveValue) => {
    setPopup((prev) => ({
      ...prev,
      isVisible: false,
      clickType,
    }));
    if (clickType === "ok" || clickType === "cancel") {
      popup.resolve(resolveValue);
    } else {
      popup.reject(new UserCancelledPopupError(`${clickType} action`)); // Promise reject on user cancel
    }
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    closePopup("discard", false);
  };

  const handleNotSend = (event) => {
    event.stopPropagation();

    closePopup("cancel", false);
  };

  const handleSend = (event) => {
    event.stopPropagation();
    closePopup("ok", true); // true if notifying guests
  };

  return ReactDOM.createPortal(
    <FocusTrap>
      <div className={styles.modalContainer} ref={guestPopupRef}>
        <div className={styles.modalHeader}>
          <div
            className={clsx(styles.headerTitle, {
              [styles.deleteHeaderTitle]: popup.type === "delete",
            })}
          >
            {popup.headerTitle}
          </div>
          <div className={styles.headerClose} onClick={handleCancel}>
            <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
          </div>
        </div>
        <div className={styles.modalMessageContainer}>
          <span className={styles.modalMessage}>{popup.message}</span>
        </div>
        <div className={styles.buttonArea}>
          <div className={styles.buttonLeftArea}>
            <button
              className={styles.modalDiscardButton}
              onClick={handleCancel}
              ref={cancelButtonRef}
            >
              {popup.discardButtonTitle}
            </button>
          </div>
          <div className={styles.buttonRightArea}>
            <button
              className={styles.modalCloseButton}
              onClick={handleNotSend}
              ref={notSendButtonRef}
            >
              {popup.closeButtonTitle}
            </button>
            <button className={styles.modalOkButton} onClick={handleSend} ref={sendButtonRef}>
              {popup.okButtonTitle}
            </button>
          </div>
        </div>
      </div>
    </FocusTrap>,
    document.getElementById("popup-root")
  );
}

export default GuestPopup;
