import { useEffect, useState } from "react";

import useApi from "../../services/auth/useApi";

import styles from "./Integrations.module.css";

import gmailIcon from "../../assets/Settings/gmail-icon.svg";
import googleCalendarIcon from "../../assets/Settings/google-calendar-icon.svg";
import deleteIcon from "../../assets/Settings/integrations-delete.png";
import addIcon from "../../assets/Settings/invitation-add.png";
import jiraIcon from "../../assets/Settings/jira-icon.svg";
import slackIcon from "../../assets/Settings/slack-icon.svg";
import { useJuneTrackCall } from "../../utils/june/analytics";

const ConnectionType = {
  GOOGLE_CALENDAR: "gcalendar",
  GOOGLE_MAIL: "gmail",
  JIRA: "jira",
  SLACK: "slack",
};

const MyConnection = ({ type, email, integrationId }) => {
  const getImagePath = () => {
    switch (type) {
      case ConnectionType.GOOGLE_CALENDAR:
        return googleCalendarIcon;
      case ConnectionType.GOOGLE_MAIL:
        return gmailIcon;
      case ConnectionType.JIRA:
        return jiraIcon;
      case ConnectionType.SLACK:
        return slackIcon;
      default:
        return googleCalendarIcon;
    }
  };

  const getAltDesc = () => {
    return type;
  };

  const getAuthUrl = () => {
    switch (type) {
      case ConnectionType.GOOGLE_CALENDAR:
        return "auth/google/secondary";
      case ConnectionType.GOOGLE_MAIL:
        return "integrations/gmail/auth";
      case ConnectionType.JIRA:
        return "integrations/jira/auth";
      case ConnectionType.SLACK:
        return "integrations/slack/auth";
      default:
        return googleCalendarIcon;
    }
  };

  const getTitle = () => {
    switch (type) {
      case ConnectionType.GOOGLE_CALENDAR:
        return "Google Calendar";
      case ConnectionType.GOOGLE_MAIL:
        return "Gmail";
      case ConnectionType.JIRA:
        return "Jira";
      case ConnectionType.SLACK:
        return "Slack";
      default:
        return "";
    }
  };

  const imagePath = getImagePath();
  const altDesc = getAltDesc();
  const authUrl = getAuthUrl();
  const title = getTitle();

  return { imagePath, altDesc, authUrl, title, type, email, integrationId };
};

function MyConnectionItem({ index, connection, isPrimaryEmail, onDelete }) {
  const api = useApi();

  const handleIntegrationDelete = () => {
    if (ConnectionType.GOOGLE_CALENDAR === connection.type) {
      api
        .delete("accounts/secondary/", {
          data: {
            secondaryEmail: connection.email,
          },
        })
        .then(() => {
          onDelete(index);
        });
    } else {
      api.delete("integrations/" + connection.integrationId).then((res) => {
        onDelete(index);
      });
    }
  };

  return (
    <div className={styles.my__connection__item}>
      <img
        className={styles.my__connection__item__Icon}
        src={connection.imagePath}
        alt={connection.altDesc}
      />
      <div className={styles.my__connection__info}>
        <div className={styles.my__connection__title}>{connection.title}</div>
        <div className={styles.my__connection__email}>{connection.email}</div>
      </div>

      {isPrimaryEmail ? null : (
        <>
          <div className={styles.my__connection__delete} onClick={handleIntegrationDelete}>
            <img className={styles.my__connection__delete__Icon} src={deleteIcon} alt="delete" />
          </div>
        </>
      )}
    </div>
  );
}

function ConnectionItem({ connection }) {
  const api = useApi();
  const trackCall = useJuneTrackCall();

  const handleGoogleLogin = () => {
    trackCall("integrate_tool", { type: connection.type });

    api.get(connection.authUrl).then((res) => {
      window.location.href = res.data.redirectUrl;
    });
  };

  return (
    <div className={styles.connections__item} onClick={handleGoogleLogin}>
      <img src={connection.imagePath} alt={connection.altDesc} />
      <div className={styles.connections__title}>{connection.title}</div>
      <img src={addIcon} alt="add" />
    </div>
  );
}

function Integrations({ loginData, onReLoadLoginData }) {
  const api = useApi();

  const [myConnections, setMyConnections] = useState([]);
  const [allConnections, setallConnections] = useState([
    // MyConnection({
    //   type: ConnectionType.GOOGLE_CALENDAR,
    //   email: "",
    //   integrationId: "",
    // }),
    // MyConnection({
    //   type: ConnectionType.GOOGLE_MAIL,
    //   email: "",
    //   integrationId: "",
    // }),
    // MyConnection({
    //   type: ConnectionType.JIRA,
    //   email: "",
    //   integrationId: "",
    // }),
    // MyConnection({
    //   type: ConnectionType.SLACK,
    //   email: "",
    //   integrationId: "",
    // }),
  ]);

  const handleMyConnectionDelete = () => {
    onReLoadLoginData();
  };

  useEffect(() => {
    const calendarConnections = loginData.emails.map((email) => {
      return MyConnection({
        type: ConnectionType.GOOGLE_CALENDAR,
        email: email,
        integrationId: "",
      });
    });

    // const otherConnections = loginData.integrations.map((integration) => {
    //   switch (integration.kind) {
    //     case ConnectionType.GOOGLE_MAIL:
    //       return MyConnection({
    //         type: ConnectionType.GOOGLE_MAIL,
    //         email: integration.data.email,
    //         integrationId: integration.id,
    //       });
    //     case ConnectionType.JIRA:
    //       return MyConnection({
    //         type: ConnectionType.JIRA,
    //         email: integration.data.resourceName,
    //         integrationId: integration.id,
    //       });
    //     case ConnectionType.SLACK:
    //       return MyConnection({
    //         type: ConnectionType.SLACK,
    //         email: integration.data.teamName,
    //         integrationId: integration.id,
    //       });
    //   }

    //   return MyConnection({ type: "", email: "", integrationId: "" });
    // }, []);

    const combineArr = [...calendarConnections];

    setMyConnections(combineArr);
  }, [loginData.emails]);

  return (
    <div className={styles.integrations__contents__area}>
      <div className={styles.integrations__contents}>
        <span className={styles.title}>My Connections</span>
        <div className={styles.my__connections__area}>
          {myConnections.length !== 0
            ? myConnections.map((connection, index) => {
                return (
                  <MyConnectionItem
                    key={index}
                    index={index}
                    connection={connection}
                    isPrimaryEmail={index === 0}
                    onDelete={handleMyConnectionDelete}
                  />
                );
              })
            : null}
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.integrations__contents}>
        <span className={styles.title}>All Connections</span>
        <span className={styles.description}>The more you connect, the less you miss</span>
        <div className={styles.connections}>
          {allConnections.map((connection, index) => {
            return <ConnectionItem connection={connection} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Integrations;
