import { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useRecoilState } from "recoil";
import clsx from "clsx";

import { recurringPopupState } from "../../../recoil/popup/recurringPopupState";

import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";
import selectedRadioBtn from "../../../assets/Popup/selected-radio-btn.svg";
import unselectedRadioBtn from "../../../assets/Popup/unselected-radio-btn.svg";

import styles from "./RecurringPopup.module.css";
import FocusTrap from "focus-trap-react";
import UserCancelledPopupError from "../../../errors/UserCancelledPopupError";

function RecurringPopup({ recurringPopupRef }) {
  const [popup, setPopup] = useRecoilState(recurringPopupState);

  const firstOptionRef = useRef(null);
  const secondOptionRef = useRef(null);
  const thirdOptionRef = useRef(null);
  const closeButtonRef = useRef(null);
  const okButtonRef = useRef(null);

  useEffect(() => {
    if (popup.isVisible && firstOptionRef.current) {
      firstOptionRef.current.focus();
    }
  }, [popup.isVisible]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;
      if (key === "ArrowRight" || key === "ArrowLeft") {
        event.preventDefault();
        const focusableElements = [
          firstOptionRef.current,
          secondOptionRef.current,
          ...(popup.isAllEnabled ? [thirdOptionRef.current] : []),
          closeButtonRef.current,
          okButtonRef.current,
          closeButtonRef.current,
          okButtonRef.current,
        ];

        const currentFocusedIndex = focusableElements.indexOf(document.activeElement);
        const nextIndex =
          key === "ArrowRight"
            ? (currentFocusedIndex + 1) % focusableElements.length
            : (currentFocusedIndex - 1 + focusableElements.length) % focusableElements.length;
        focusableElements[nextIndex].focus();
      } else if (key === "Enter") {
        document.activeElement.click();
      } else if (key === "Escape") {
        handleClose(event);
      }
    };

    if (popup.isVisible) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [popup.isVisible, popup.shouldShowAll]);

  if (!popup.isVisible) return null;

  const closePopup = (clickType, result) => {
    setPopup((prev) => ({
      ...prev,
      messageType: "current",
      isVisible: false,
      clickType,
    }));

    if (clickType === "ok") {
      if (popup.resolve) {
        popup.resolve(result); // Promise resolve
      }
    } else {
      popup.reject(new UserCancelledPopupError(`${clickType} action`)); // Promise reject on user cancel
    }
  };

  const handleClose = (event) => {
    event.stopPropagation();
    closePopup("cancel", "cancel");
    setPopup((prev) => ({ ...prev, messageType: "cancel" }));
  };

  const handleOk = (event) => {
    event.stopPropagation();
    closePopup("ok", popup.messageType); // resolve with selected option
  };

  const handleOptionChange = (event) => {
    setPopup((prev) => ({ ...prev, messageType: event.target.value }));
  };

  return ReactDOM.createPortal(
    <FocusTrap>
      <div className={styles.modalContainer} ref={recurringPopupRef}>
        <div className={styles.modalHeader}>
          <div
            className={clsx(styles.headerTitle, {
              [styles.deleteHeaderTitle]: popup.type === "delete",
            })}
          >
            {popup.headerTitle}
          </div>
          <div className={styles.headerClose} onClick={handleClose}>
            <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
          </div>
        </div>
        <div className={styles.repeatMessageConatiner}>
          <label className={styles.radioContainer} tabIndex="0" ref={firstOptionRef}>
            <input
              type="radio"
              name="option"
              value="current"
              checked={popup.messageType === "current"}
              onChange={handleOptionChange}
            />
            {popup.messageType === "current" ? (
              <img src={selectedRadioBtn} alt="Radio button" />
            ) : (
              <img
                src={unselectedRadioBtn}
                alt="Radio button"
                className={styles["unselected-radioIcon"]}
              />
            )}
            <span>This event</span>
          </label>
          <label className={styles.radioContainer} tabIndex="0" ref={secondOptionRef}>
            <input
              type="radio"
              name="option"
              value="from"
              checked={popup.messageType === "from"}
              onChange={handleOptionChange}
            />

            {popup.messageType === "from" ? (
              <img src={selectedRadioBtn} alt="Radio button" />
            ) : (
              <img
                src={unselectedRadioBtn}
                alt="Radio button"
                className={styles["unselected-radioIcon"]}
              />
            )}
            <span>This event and following events</span>
          </label>

          {(popup.isAllEnabled || popup.type === "delete") && (
            <label className={styles.radioContainer} tabIndex="0" ref={thirdOptionRef}>
              <input
                type="radio"
                name="option"
                value="all"
                checked={popup.messageType === "all"}
                onChange={handleOptionChange}
              />
              {popup.messageType === "all" ? (
                <img src={selectedRadioBtn} alt="Radio button" />
              ) : (
                <img
                  src={unselectedRadioBtn}
                  alt="Radio button"
                  className={styles["unselected-radioIcon"]}
                />
              )}
              <span>All events</span>
            </label>
          )}
        </div>

        <div className={styles.buttonArea}>
          <div className={styles.buttonLeftArea}></div>
          <div className={styles.buttonRightArea}>
            <button className={styles.modalCloseButton} onClick={handleClose} ref={closeButtonRef}>
              {popup.closeButtonTitle}
            </button>
            <button className={styles.modalOkButton} onClick={handleOk} ref={okButtonRef}>
              {popup.okButtonTitle}
            </button>
          </div>
        </div>
      </div>
    </FocusTrap>,
    document.getElementById("popup-root")
  );
}

export default RecurringPopup;
