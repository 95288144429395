import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
import DatePicker from "react-datepicker";

import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import formatDate from "../../../utils/common/dateTime/formatDate";

import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";

import clsx from "clsx";

import styles from "./RepeatAddModal.module.css";

export default function RepeatAddModal({
  setIsRepeatAddModal,
  setIsCustomRepeatSetted,
  repeatAddModalRef,
  dateStart,
}) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const { data } = taskDetail;
  const mainRef = useRef(null);
  const backgroundRef = useRef(null);
  const [selectedRepeatDay, setSelectedRepeatDay] = useState([]);

  const [date, setDate] = useState(new Date());
  const [isDate, setIsDate] = useState(false);
  const [isUnitDrop, setIsUnitDrop] = useState(false);
  const [unitDrop, setUnitDrop] = useState("week");
  const [isRepeatMonth, setIstRepeatMonth] = useState(false);
  const [repeatMonth, setRepeatMonth] = useState("day");

  const handleDateChange = (e) => {
    setIsDate(!isDate);
    setDate(e);
  };

  const handleDate = (e) => {
    if (e.currentTarget != e.target) return;
    e.preventDefault();

    setIsDate(!isDate);
  };

  useEffect(() => {
    const today = getRFC5545DayName(new Date());
    setSelectedRepeatDay([today]);
  }, []);

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.stopPropagation();
      handleCreate();
    }
    if (e.key === "Escape") {
      e.stopPropagation();
      setIsRepeatAddModal(false);
    }
  }

  useEffect(() => {
    function handleClickOutside(e) {
      if (repeatAddModalRef.current && !repeatAddModalRef.current.contains(e.target)) {
        setIsRepeatAddModal(false);
      }
    }

    if (repeatAddModalRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsRepeatAddModal, repeatAddModalRef, selectedRepeatDay]);

  const handleCreate = () => {
    const customRecurrence = generateCustomRecurrence();
    if (selectedRepeatDay.length > 0) {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          recurrence: [customRecurrence],
        },
      }));

      setIsCustomRepeatSetted(true);
    }
    setIsRepeatAddModal(false);
  };

  const generateCustomRecurrence = () => {
    let recurrenceRule = "";

    const repeatNumber = document.querySelector(`.${styles["repeat-number"]}`).value;
    const frequencyMap = {
      day: "DAILY",
      week: "WEEKLY",
      month: "MONTHLY",
      year: "YEARLY",
    };
    recurrenceRule += `RRULE:FREQ=${frequencyMap[unitDrop]};INTERVAL=${repeatNumber}`;

    if (unitDrop === "week") {
      if (selectedRepeatDay.length > 0) {
        recurrenceRule += `;BYDAY=${selectedRepeatDay.join(",")}`;
      }
    } else if (unitDrop === "month") {
      if (repeatMonth === "day") {
        recurrenceRule += `;BYMONTHDAY=${currentDayOfMonth - 1}`;
      } else if (repeatMonth === "week") {
        recurrenceRule += `;BYSETPOS=${currentWeekOfMonth};BYDAY=${currentTwoAlphabetDayName}`;
      }
    }

    const selectedRadio = document.querySelector('input[name="repeat"]:checked');
    if (selectedRadio) {
      const radioLabel = selectedRadio.nextElementSibling.nextElementSibling.innerText;
      if (radioLabel === "On") {
        recurrenceRule += `;UNTIL=${formatDateUNTIL(date)}`;
      } else if (radioLabel === "After") {
        const finishedAfterInput = document.querySelector(
          `.${styles["finished-after-input"]}`
        ).value;
        recurrenceRule += `;COUNT=${finishedAfterInput}`;
      }
    }

    return recurrenceRule;
  };

  const getWeekOfMonth = (date) => {
    const startWeek = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    const dayOfMonth = date.getDate();
    return Math.ceil((dayOfMonth + startWeek) / 7);
  };

  const getRFC5545DayName = (date) => {
    const dayOfWeek = date.getDay();
    const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    return days[dayOfWeek];
  };

  const getFullDayName = (date) => {
    const dayOfWeek = date.getDay();
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[dayOfWeek];
  };

  const formatDateUNTIL = (date) => {
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());

    return `${year}${month}${day}`;
  };

  const padZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const numberToOrdinal = (number) => {
    const ordinals = ["first", "second", "third", "fourth", "fifth"];
    return ordinals[number - 1];
  };

  const handleRepeatDay = (name) => {
    const today = getRFC5545DayName(new Date());
    if (selectedRepeatDay.includes(name)) {
      if (selectedRepeatDay.length > 1 || name !== today) {
        const newSelectedRepeatDay = selectedRepeatDay.filter((data) => data !== name);
        setSelectedRepeatDay(newSelectedRepeatDay.length === 0 ? [today] : newSelectedRepeatDay);
      }
    } else {
      setSelectedRepeatDay([...selectedRepeatDay, name]);
    }
  };
  const handleBackgroundClick = (e) => {
    e.stopPropagation();

    if (repeatAddModalRef.current && repeatAddModalRef.current.contains(e.target)) {
      // mainRef 요소에서 클릭 이벤트가 발생한 경우, 아무것도 하지 않습니다.
      return;
    }

    setIsRepeatAddModal(false);
  };

  const currentDayOfMonth = dateStart.getDate();
  const currentWeekOfMonth = getWeekOfMonth(dateStart);
  const currentTwoAlphabetDayName = getRFC5545DayName(dateStart);
  const currentFullDayName = getFullDayName(dateStart);

  return createPortal(
    <div className={styles["wrap"]} ref={backgroundRef} onMouseDown={handleBackgroundClick}>
      <div className={styles["main"]} ref={repeatAddModalRef}>
        <div className={styles["header"]}>
          <span className={styles["header-title"]}>New repeat setting</span>
          <span
            className={styles["header-close"]}
            onClick={(e) => {
              setIsRepeatAddModal(false);
            }}
          ></span>
        </div>
        <div className={styles["body"]}>
          <div className={styles["body-part"]}>
            <div className={styles["body-title"]}>Repeat</div>
            <div className={styles["body-row"]}>
              <span className={styles["repeat-text"]}>Every</span>
              <input className={styles["repeat-number"]} defaultValue="1" placeholder="1"></input>
              <div className={styles["repeat-unit"]} onClick={() => setIsUnitDrop(!isUnitDrop)}>
                {unitDrop}
                {isUnitDrop && (
                  <div className={styles["repeat-unit-drop"]}>
                    <span onClick={() => setUnitDrop("day")}>Day</span>
                    <span onClick={() => setUnitDrop("week")}>Week</span>
                    <span onClick={() => setUnitDrop("month")}>Month</span>
                    <span onClick={() => setUnitDrop("year")}>Year</span>
                  </div>
                )}
              </div>
            </div>
            <div className={`${styles["body-row"]} ${styles["row-repeat-day"]}`}>
              {unitDrop == "week" && (
                <>
                  <span
                    className={`${styles["repeat-day"]} ${
                      selectedRepeatDay.includes("SU") ? styles["repeat-day-on"] : ""
                    }`}
                    onClick={() => handleRepeatDay("SU")}
                  >
                    sun
                  </span>
                  <span
                    className={`${styles["repeat-day"]} ${
                      selectedRepeatDay.includes("MO") ? styles["repeat-day-on"] : ""
                    }`}
                    onClick={() => handleRepeatDay("MO")}
                  >
                    mon
                  </span>
                  <span
                    className={`${styles["repeat-day"]} ${
                      selectedRepeatDay.includes("TU") ? styles["repeat-day-on"] : ""
                    }`}
                    onClick={() => handleRepeatDay("TU")}
                  >
                    tue
                  </span>
                  <span
                    className={`${styles["repeat-day"]} ${
                      selectedRepeatDay.includes("WE") ? styles["repeat-day-on"] : ""
                    }`}
                    onClick={() => handleRepeatDay("WE")}
                  >
                    wed
                  </span>
                  <span
                    className={`${styles["repeat-day"]} ${
                      selectedRepeatDay.includes("TH") ? styles["repeat-day-on"] : ""
                    }`}
                    onClick={() => handleRepeatDay("TH")}
                  >
                    thu
                  </span>
                  <span
                    className={`${styles["repeat-day"]} ${
                      selectedRepeatDay.includes("FR") ? styles["repeat-day-on"] : ""
                    }`}
                    onClick={() => handleRepeatDay("FR")}
                  >
                    fri
                  </span>
                  <span
                    className={`${styles["repeat-day"]} ${
                      selectedRepeatDay.includes("SA") ? styles["repeat-day-on"] : ""
                    }`}
                    onClick={() => handleRepeatDay("SA")}
                  >
                    sat
                  </span>
                </>
              )}
              {unitDrop == "month" && (
                <div
                  className={styles["repeat-month"]}
                  onClick={() => setIstRepeatMonth(!isRepeatMonth)}
                >
                  {repeatMonth == "day"
                    ? `Monthly on day ${currentDayOfMonth}`
                    : `Monthly on the ${numberToOrdinal(currentWeekOfMonth)} ${currentFullDayName}`}
                  {isRepeatMonth && (
                    <div className={styles["repeat-month-drop"]}>
                      <span
                        onClick={() => setRepeatMonth("day")}
                      >{`Monthly on day ${currentDayOfMonth}`}</span>
                      <span onClick={() => setRepeatMonth("week")}>
                        {`Monthly on the ${numberToOrdinal(currentWeekOfMonth)} ${currentFullDayName}`}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles["body-part"]}>
            <div className={styles["body-title"]}>Finished</div>
            <div className={styles["body-row"]}>
              <label className={styles["radio"]}>
                <input
                  className={styles["radio-input"]}
                  defaultChecked="true"
                  type="radio"
                  name="repeat"
                ></input>
                <span className={styles["radio-control"]}></span>
                <span className={styles["radio-label"]}>Never</span>
              </label>
            </div>
            <div className={styles["body-row"]}>
              <label className={styles["radio"]}>
                <input className={styles["radio-input"]} type="radio" name="repeat"></input>
                <span className={styles["radio-control"]}></span>
                <span className={styles["radio-label"]}>On</span>
              </label>
              <div className={styles["finished-on-date"]} onClick={handleDate}>
                <span className={styles["finished-on-input"]} onClick={handleDate}>
                  {formatDate(date)}
                </span>
                {isDate && (
                  <div className={styles["datepicker-wrap"]}>
                    <DatePicker selected={date} onChange={handleDateChange} inline />
                  </div>
                )}
              </div>
            </div>
            <div className={styles["body-row"]}>
              <label className={styles["radio"]}>
                <input className={styles["radio-input"]} type="radio" name="repeat"></input>
                <span className={styles["radio-control"]}></span>
                <span className={styles["radio-label"]}>After</span>
              </label>
              <div className={styles["finished-after"]}>
                <input
                  className={styles["finished-after-input"]}
                  defaultValue="13"
                  placeholder="13"
                ></input>
                <span className={styles["finished-after-text"]}>Times</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(styles["footer"], {
            [styles["footer-disabled"]]: selectedRepeatDay.length == 0,
          })}
          onClick={handleCreate}
        >
          Save
        </div>
      </div>
    </div>,
    document.body
  );
}
