export function handlePopupKeyEvent(popupRef, setPopup, popup, event) {
  if (!popupRef.current) return false;

  if (event.key === "Escape") {
    event.stopPropagation();
    setPopup({ ...popup, isVisible: false });
    return true;
  }

  if (event.key === "Enter") {
    return true;
  }
}
