import { forwardRef, useEffect, useMemo } from "react";
import moment from "moment";
import ShowMoreItem from "./ShowMoreItem";
import styles from "./index.module.css";
import { useRecoilValue } from "recoil";
import { timeFormatState } from "../../../recoil/calendar/settingCalendar";

function ShowMorePopup({ date, events, onClose, children, ...props }, ref) {
  const timeFormat = useRecoilValue(timeFormatState);

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  const isToday = useMemo(() => {
    const today = new Date();
    return moment(today).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD");
  }, [date]);

  const allDayEvents = useMemo(() => events.filter((event) => event.allDay), [events]);
  const notAllDayEvents = useMemo(() => events.filter((event) => !event.allDay), [events]);

  if (!events) return null;

  return (
    <div ref={ref} className={styles.morePopup} {...props}>
      <div className={styles.dateWrapper}>
        <p className={`${styles.date} ${isToday ? styles.today : ""}`}>
          {new Date(date).getDate()}
        </p>
        <p className={styles.day}>{moment(date).format("ddd")}</p>
      </div>
      <div className={styles.eventWrapper}>
        {allDayEvents.map((event) => (
          <ShowMoreItem key={event.id} event={event}>
            {children(event)}
          </ShowMoreItem>
        ))}
        {allDayEvents.length > 0 && notAllDayEvents.length > 0 && (
          <div className={styles.divider} />
        )}
        {notAllDayEvents.map((event, idx) => (
          <div className={styles.notAllDayEventWrapper} key={event.id}>
            <div className={styles.time}>
              {(idx === 0 || String(notAllDayEvents[idx - 1].start) !== String(event.start)) &&
                moment(event.start).format(timeFormat === "24-hour" ? "HH:mm" : "hh:mm a")}
            </div>
            <ShowMoreItem event={event} className={styles.notAllDayEvent}>
              {children(event)}
            </ShowMoreItem>
          </div>
        ))}
      </div>
    </div>
  );
}

export default forwardRef(ShowMorePopup);
