import React, { useState, useRef, useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import styles from "./DoneTaskList.module.css";
import { v4 as uuid4 } from "uuid";
import { COMPLETED } from "../../constants/taskStateType";
import { accountState } from "../../recoil/account/accountState";
import { draggedEventState } from "../../recoil/calendar/calendarState";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { filteringProjectIdListState } from "../../recoil/projects/filteringProjectIdListState";
import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import TaskListEmptyDropArea from "./TaskListEmptyDropArea";
import TaskRow from "./TaskRow";
import { useDrop } from "react-dnd";
import { useUpdateTask } from "../../queries/Task";

export default function DoneTaskList({ expand, onExpandClick, loadDoneTask, setMobaEventList }) {
  const minHeight = 51;
  const [filteringProjectIdList, setFilteringProjectIdList] = useRecoilState(
    filteringProjectIdListState
  );
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [taskAddClick, setTaskAddClick] = useState(false);
  const trackCall = useJuneTrackCall();
  const inputRef = useRef(null);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState(doneTaskListState);
  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);

  const [isTaskListHoverd, setIsTaskListHoverd] = useState(false);
  const [taskSelectedId, setTaskSelectedId] = useState(null);
  const [height, setHeight] = useState(minHeight);
  const resizerRef = useRef(null);
  const lastYRef = useRef(0);
  const originalHeightRef = useRef(0);
  const containerTopOffset = useRef(80);
  const [animating, setAnimating] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const api = useApi();

  const updateTask = useUpdateTask();

  // scroll 숨기기
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  useEffect(() => {
    if (expand) {
      setTaskAddClick(false);
    } else {
      setHeight(minHeight);
    }
  }, [expand]);

  useEffect(() => {
    if (taskAddClick && inputRef.current) {
      inputRef.current.focus();
    }
  }, [taskAddClick]);

  const [isEnter, setIsEnter] = useState(false);

  useEffect(() => {
    if (inputRef.current && !isEnter) {
      inputRef.current.focus();
    }
  }, [inputRef, isEnter]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setTaskAddClick(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  };

  useEffect(() => {
    const element = document.querySelector(`.${styles.taskListWrapper}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  const handleDataDelete = (rowData) => {
    updateInboxTaskList((current) => {
      const updatedTasks = current.filter((task) => task.id !== rowData.id);
      return updatedTasks;
    });

    api
      .patch("tasks/" + rowData.id + "/mark", "", {
        headers: { "X-Requester": rowData.creator },
      })
      .then(() => {
        // NOTE inbox에서는 무조건 type task(guest 추가 시 시간 추가로 캘린더에 설정 필수이기 때문)
        trackCall("delete_block", { location: "inbox", type: "task" });
      });
  };

  const handleDataDuplicate = (rowData) => {
    const newId = `task-${uuid4()}`;

    let newItem = {
      ...rowData,
      id: newId,
    };

    const index = inboxTaskList.findIndex((item) => item.id === rowData.id);

    let tempArr = [
      ...inboxTaskList.slice(0, index + 1),
      newItem,
      ...inboxTaskList.slice(index + 1),
    ];

    updateInboxTaskList(tempArr);

    api
      .post("tasks", newItem, {
        headers: {
          "X-Requester": accountData.accountInfo.accounts[0].email,
        },
      })
      .then((res) => {
        let trackObject = { location: "inbox" };
        if (newItem.attendees != null && newItem.attendees.length) {
          trackObject = { ...trackObject, type: "meeting" };
        } else {
          trackObject = { ...trackObject, type: "task" };
        }

        trackCall("duplicate_block", trackObject);

        const taskIdArr = tempArr.map((taskData) => taskData.id);

        // console.log(tempArr);
        // console.log(
        //   tempArr.map((taskData) => {
        //     return { id: taskData.id, title: taskData.title };
        //   })
        // );

        const primaryAccountInfo = accountData.accountInfo.accounts.find(
          (account) => account.type === "primary"
        );

        const newItems = { taskIds: taskIdArr, status: COMPLETED };

        api
          .post("/tasks/order", newItems, {
            headers: {
              "X-Requester": primaryAccountInfo.email,
            },
          })
          .then((res) => {})
          .catch((error) => {
            loadDoneTask();
          });
      });
  };

  const handleTasksReorderChange = () => {
    const taskIdArr = inboxTaskList.map((taskData) => taskData.id);

    const primaryAccountInfo = accountData.accountInfo.accounts.find(
      (account) => account.type === "primary"
    );

    // console.log(
    //   inboxTaskList.map((taskData) => {
    //     return { id: taskData.id, title: taskData.title };
    //   })
    // );

    api
      .post(
        "/tasks/order",
        { taskIds: taskIdArr, status: COMPLETED },
        {
          headers: {
            "X-Requester": primaryAccountInfo.email,
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        loadDoneTask();
      });
  };

  const handleSaveTaskSelectedId = (id) => {
    setTaskSelectedId(id);
  };

  const [{ isOver }, drop] = useDrop({
    accept: ["integrationDrag"],
    drop(props, monitor) {
      const item = monitor.getItem();
      const updatedDragItem = {
        ...item,
        itemStatus: COMPLETED,
        taskType: "Task",
      };

      updateInboxTaskList((prevTask) => {
        return [updatedDragItem, ...prevTask];
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleUpdateEventToTask = async (event) => {
    const { end, start, dragType, ...rest } = event;
    const newTask = { ...rest, itemStatus: "Completed" };
    Object.keys(newTask).forEach((key) => {
      if (newTask[key] === null) delete newTask[key];
    });

    const previousInboxTasks = [...inboxTaskList];

    try {
      setMobaEventList((prev) => prev.filter((item) => item.id !== newTask.id));
      updateInboxTaskList((current) => [newTask, ...current]);
      await updateTask.mutateAsync(newTask);
    } catch (error) {
      setMobaEventList((prev) => [...prev, newTask]);
      updateInboxTaskList(previousInboxTasks);
    } finally {
      trackCall("move_task");
    }
  };

  const memoizedTaskList = useMemo(() => {
    const projectIds = filteringProjectIdList;

    if (!inboxTaskList || inboxTaskList.length === 0) {
      return (
        <TaskListEmptyDropArea
          onTasksReorderChange={handleTasksReorderChange}
          handleUpdateTaskList={updateInboxTaskList}
          listType={"doneTask"}
        />
      );
    }

    return inboxTaskList
      .filter((task) => !projectIds.includes(task.projectId))
      .map((taskDataRow, index) => (
        <TaskRow
          key={taskDataRow.id}
          data={taskDataRow}
          index={index}
          expand={expand}
          onDataDelete={handleDataDelete}
          onDataDuplicate={handleDataDuplicate}
          loadData={loadDoneTask}
          onTasksReorderChange={handleTasksReorderChange}
          taskSelectedId={taskSelectedId}
          onSaveTaskSelectedId={handleSaveTaskSelectedId}
          taskList={inboxTaskList}
          handleUpdateTaskList={updateInboxTaskList}
          listType={"doneTask"}
        />
      ));
  }, [inboxTaskList, filteringProjectIdList, expand, taskSelectedId, handleSaveTaskSelectedId]);

  // 2024.05.15 추후 높이 조절이 필요 할 경우 주석만 제거
  // const startResize = (event) => {
  //   setIsDragging(false);
  //   lastYRef.current = event.clientY;
  //   originalHeightRef.current = height;

  //   setAnimating(false);

  //   document.addEventListener("mousemove", resize);
  //   document.addEventListener("mouseup", stopResize);
  // };
  // const resize = (event) => {
  //   setIsDragging(true);
  //   const deltaY = event.clientY - lastYRef.current;
  //   const newHeight = originalHeightRef.current - deltaY;
  //   const maxHeight = window.innerHeight - containerTopOffset.current;

  //   if (newHeight >= minHeight && newHeight <= maxHeight) {
  //     setHeight(newHeight);
  //   }
  // };
  // const stopResize = () => {
  //   setAnimating(false);

  //   document.removeEventListener("mousemove", resize);
  //   document.removeEventListener("mouseup", stopResize);
  // };

  const handleDoneTaskListUpDown = () => {
    if (isDragging) {
      setIsDragging(false);
      return;
    }

    const maxHeight = window.innerHeight - containerTopOffset.current;
    setAnimating(true);
    setHeight(height === minHeight ? maxHeight : minHeight);
    if (!expand) onExpandClick();
  };

  return (
    <>
      <div
        className={styles.doneTaskList}
        style={{
          height: `${height}px`,
          transition: animating ? "height 0.3s ease-in-out" : "none",
        }}
        ref={resizerRef}
      >
        <div
          className={`${styles.titleRow} ${expand ? "" : styles.doneTaskListFold}`}
          onClick={handleDoneTaskListUpDown}
          // 2024.05.15 추후 높이 조절이 필요 할 경우 주석만 제거
          // onMouseDown={startResize}
        >
          <div
            className={`${styles.titleIcon} ${height <= minHeight ? styles.titleIconRotate : null}`}
          ></div>
          {expand && (
            <>
              <div className={styles.titleMain}>completed</div>
              <div className={styles.doneTaskCount}>{inboxTaskList && inboxTaskList.length}</div>
            </>
          )}
        </div>
        <div
          ref={drop}
          onMouseEnter={() => {
            if (draggedEvent && draggedEvent.dragType === "calendarTask") {
              setIsTaskListHoverd(true);
            }
          }}
          onMouseLeave={() => {
            setIsTaskListHoverd(false);
          }}
          onMouseUp={() => {
            if (draggedEvent && draggedEvent.dragType === "calendarTask") {
              handleUpdateEventToTask(draggedEvent);
              setIsTaskListHoverd(false);
            }
          }}
          className={`${styles.taskListWrapper}  ${isOver || isTaskListHoverd ? styles.hover : ""} ${isScrolling && styles.showScrollbar}`}
        >
          {memoizedTaskList}
        </div>
      </div>
    </>
  );
}
