import React from "react";
import styles from "./TimelineEmpty.module.css";
import { TimelineDot } from "../TimelineDot/TimelineDot";

interface TimelineEmptyProps {
  dotColor: string;
}
export const TimelineEmpty: React.FC<TimelineEmptyProps> = ({ dotColor }) => {
  return (
    <div className={styles.guide__message__area}>
      <TimelineDot color={dotColor} />
      <div className={styles.guide__message__box}>
        If you block the time with tasks, they will be grouped into a timeline here!
      </div>
    </div>
  );
};
